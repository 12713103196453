@import "../../../styles/fonts";
@import "../../../styles/colors";

.formItem{
  .ant-form-item-required{
    font-style: normal;
    font-weight: 400;
    font-family: 'Poppins' sans-serif !important;
    color: #463649 !important;
  }

  .datePicker{
    width: 100%;
    height: 48px;
    border: 1px solid #CC8DFF;
    border-radius: 10px;
    padding: 0 5px 0 20px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #463649 !important;

    .ant-picker-suffix{
      margin-right: 10px;
    }

    .ant-picker-input{
      input{  
        &::placeholder{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #463649;
        }
      }

      .anticon{
        color: #463649;
      }
    }

    
  }
}