@import "../../../../styles/fonts";
@import "../../../../styles/colors";

.register-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .frame {
    display: flex;
    width: 1086px;
    max-width: 100%;
    height: 900px;
    background: #fff;
    border-radius: 34px;
    overflow: hidden;
    margin: 2% 0;

    .couple-img {
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }

    .information {
      width: 49.35%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .contain {
        width: 100%;
        padding: 118px 88px 70px 88px;
        text-align: left;
        font-size: 13px;

        h1 {
          font-size: 50px;
          font-family: $recoleta;
        }

        .list {
          .item-list {
            padding-left: 43px;
            position: relative;
            margin-bottom: 19px;
            font-size: 13px;
            line-height: 19.5px;

            &::before {
              content: url("../../../../../public/assets/blue-heart.png");
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .register {
      width: 50.65%;
      padding: 121px 88px 111px 88px;
      text-align: left;
      font-size: 13px;
      background: linear-gradient(0deg, #f7f7ff, #f7f7ff);
      border-radius: 0 0 34px 34px;

      .input-field {
        margin-bottom: 21px;
      }

      .button-default {
        margin-top: 22px;
        margin-bottom: 13px;
      }
      .error-msg{
        color: #FF4D4F;
        font-size: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
      }

      .primaryDisabled{
        background-color: #E9C6FC;
      }

      a {
        font-weight: 500;
        color: #463649;
      }

      p {
        margin-top: 54px;

        &.details {
          margin-top: 30px;
          margin-bottom: 31px;
        }

        a {
          font-weight: 700;
          color: #cc8dff;
        }
      }

      @media (max-width: 620px) {
        padding: 67px 10% 20px 10%;
      }
    }
  }

  @media (max-width: 920px) {
    height: auto;
    
    .frame {
      display: flex;
      flex-wrap: wrap;
      height: auto;

      .information,
      .register {
        width: 100%;
      }

      .register {
        form {
          margin: auto;
        }
      }

      .information {
        .contain {
          padding: 67px 10% 20px 10%;
          h1 {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) { 
  .register-screen{
    .frame{
    border-radius: 0;

      .information{
        .contain{
          img{
            width: 100px;
          }
          h1{
            margin-top: 15%;
            margin-bottom: 10%;
          }
        }
      }

      .register{
        background: #FFF;
        padding-top: 15px;

        p {
            &.details {
            margin-top: 35px;
            margin-bottom: 35px;
          }
        }

        .button-default {
          margin-top: 40px;
          margin-bottom: 20px !important;
        }
      }

    }
  } 
}

@media (max-width: 920px) {
  .bg-register {
    overflow: auto !important /*To overwrite styles inline*/;
    height: auto;
  }
}
