.Success {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff url('../../../../../../public/assets/bg-succcess.svg') no-repeat center center;
    box-shadow: 0px 25px 50px 20px rgba(0, 0, 0, 0.01);
    border-radius: 34px;
    min-height: 824px;
    max-width: 1241px;
    margin: 35px auto;
    padding: 51px 20px;
  }

  img {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 50px;
    margin: 0;
    text-align: center;
  }

  h2 {
    font-size: 50px;
    line-height: 54.5px;
    margin: 0;
    text-align: center;
  }

  h3 {
    font-size: 30px;
    line-height: 32.7px;
    margin: 54px 0 22px;
  }

  .button-default {
    max-width: 235px;
  }

  .backToGame{
    margin-top: 30px !important;
  }

  form {
    width: 100%;
    max-width: 390px;

    .input-field {
      margin-bottom: 22px;
    }

    .button-default {
      width: 100%;
      max-width: 100%;
      margin: -5px 0;
    }
  }
}

@media (max-width: 450px) { 
  .Success {
    img{
      width: 100px;
      height: 100px;
    }

    h1{
      font-size: 30px;
    }
    h3{
      font-size: 20px;
      margin-top: 15%;
      width: 100%;
      text-align: left;
    }
    .prefrenceBtn{
      margin-top: 8% !important;
    }
  }
}