.page-container {
  max-width: 1265px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .page-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@font-face {
  font-family: 'gender-reveal-icon';
  src: url('../../../public/assets/fonts/gender-reveal-icon.eot?ckfarx');
  src: url('../../../public/assets/fonts/gender-reveal-icon.eot?ckfarx#iefix') format('embedded-opentype'), url('../../../public/assets/fonts/gender-reveal-icon.ttf?ckfarx') format('truetype'), url('../../../public/assets/fonts/gender-reveal-icon.woff?ckfarx') format('woff'), url('../../../public/assets/fonts/gender-reveal-icon.svg?ckfarx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.gender-reveal-icon-instagram:before {
  content: "\e900";
  font-family: 'gender-reveal-icon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  margin: 0px;
  box-sizing: border-box;
}

body * {
  line-height: inherit;
  box-sizing: border-box;
  font-family: inherit;
}

.layout {
  max-width: 1265px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .layout {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.layout a {
  text-decoration: underline;
  color: inherit;
}

.layout .link-item {
  text-decoration: none;
}

.layout .main-content {
  display: grid;
  grid-auto-columns: auto max-content;
  grid-auto-flow: column;
}

.layout .main-content .banner {
  position: relative;
}

.layout .main-content .banner img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.layout .main-content .content {
  max-width: 25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}

.layout .main-content .content .content-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 2em 1em;
  background: white;
}

.layout .main-content .content .content-head .logo {
  width: 10em;
}

.layout .main-content .content .content-head .hero {
  margin: 0.2em 0;
  line-height: 1;
  font-weight: bold;
  text-align: center;
}

.layout .main-content .content .form-container {
  padding: 2em;
  background: rgba(163, 195, 216, 0.75);
}

.layout .main-content .content .form-container .legend {
  margin: 0 0 1.3em;
  text-align: center;
  font-weight: 700;
}

.layout .main-content .content .form-container .input-row {
  margin: 1.3em 0;
  width: 100%;
}

.layout .main-content .content .form-container .input-row .fields {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.layout .main-content .content .form-container .input-row .fields .data-input {
  width: 100%;
  padding: 0.8em;
  border-radius: 5px;
  border: none;
  outline: none;
}

.layout .main-content .content .form-container .input-row .fields.two {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5em;
}

.layout .main-content .content .form-container .submit-btn {
  background: #EB93AF;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.8em;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.layout .main-content .content .form-container .submit-btn:hover {
  background: #e986a5;
}

.layout .main-content .content .form-container .terms-and-policy {
  text-align: center;
  font-weight: 600;
  font-size: 0.95em;
  margin: 1.2em 0 0;
}

.layout .main-content .content .form-container .about-chart {
  text-align: center;
  margin: 2em 0 0;
}

@media only screen and (max-width: 875px) {
  .layout .main-content {
    grid-auto-flow: row;
  }

  .layout .main-content .banner img {
    position: relative;
    max-height: 500px;
    margin-bottom: -5px;
  }

  .layout .main-content .content {
    width: 100%;
    max-width: none;
    padding-top: 0;
  }

  .layout .main-content .content .content-head {
    position: fixed;
    padding: 1em 2em;
    background: rgba(255, 255, 255, 0.89);
    top: 0;
  }

  .layout .main-content .content .content-head .logo {
    width: 7em;
  }

  .layout .main-content .content .content-head .hero {
    margin-top: 0.5em;
  }

  .layout .main-content .content .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .layout .main-content .content .form-container .form {
    max-width: 585px;
    width: 100%;
  }
}

.layout .bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4em 1em 2em;
}

.layout .bottom-content .block-1 {
  margin-bottom: 2.5em;
}

.layout .bottom-content .block-1> :nth-child(1) {
  margin-bottom: 1.5em;
}

.layout .bottom-content .block-1> :nth-child(2) {
  font-weight: bold;
  font-size: 1.2em;
}

.layout .bottom-content .block-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5em;
}

.layout .bottom-content .block-2> :nth-child(2) {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2em;
  width: 100%;
  justify-content: space-around;
}

.layout .bottom-content .block-2> :nth-child(2)>* {
  font-size: 3em !important;
  width: max-content;
}

.layout .bottom-content .block-2> :nth-child(2) .gender-reveal-icon-instagram {
  margin-top: -0.06em;
  text-decoration: none;
}

.layout .bottom-content .block-2> :nth-child(2) .gender-reveal-icon-instagram::before {
  font-size: 1.1em;
}

.layout .bottom-content .block-3 {
  margin-bottom: 4em;
}

.layout .bottom-content .block-3>* {
  font-weight: bold;
  margin: 0;
}

.layout .bottom-content .block-4 {
  max-width: 33em;
}

@media only screen and (max-width: 875px) {
  .layout {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .layout {
    min-width: 100% !important;
  }
}