@import "../../styles/fonts";
@import "../../styles/colors";

.button-link {
  background-color: $color-4;
  text-decoration: none;
  color: #fff;
  padding: 18px 24px 17px;
  font-weight: 700;
  border-radius: 10px;
  transition: background-color 0.3s;
  font-family: $recoleta;

  &:hover {
    background-color: $color-5;
  }
}
