@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.DashboardScreen {
  overflow: hidden;

  h1 {
    font-family: $recoleta;
    font-size: 50px;
    margin-bottom: 32px;
    margin-top: 0;
  }

  .frame {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1241px;
    max-width: 100%;
    min-height: 1006px;
    background: #fff;
    border-radius: 34px;
    margin: 35px auto 0;
    padding: 67px 52px 52px 70px;
    box-sizing: border-box;

    .outlet {
      flex: 1 1 53%;
      width: 53%;
      padding-right: 46px;
    }

    .SidebarDashboard {
      flex-basis: 36%;
      width: 36%;
    }
  }

  @media (max-width: 1024px) {
    .frame {
      padding: 40px 20px;
    }
  }

  @media (max-width: 768px) {
    .frame {
      .outlet,
      .SidebarDashboard {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }

  
}

#root > div.modal-small.active > div.body-modal > div > div.socialIconsBox{
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}