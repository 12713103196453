@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.votingDetailsCard{
    padding: 30px 22px !important;
    background: #F2F0F2;
    height: 250px;
    width: 100% !important;
    overflow: hidden;
    .data {
        position: relative;
        margin-bottom: 10px;
  
        .header {
          padding: 0 43px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          font-family: $recoleta;
          padding-bottom: 10px;
          margin-bottom: 5px;
        }
  
        ul.list {
          padding: 0 43px 0;
          margin: 15px 0;
          max-height: 135px;
          overflow: auto;
  
          &::-webkit-scrollbar {
            width: 3px;
            background-color: transparent;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: $color-8;
            border-radius: 30px;
          }
  
          li {
            font-size: 16px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #463649;

            .locked{
              color: $color-6;
            }

            .id{
                font-weight: 700;
                font-size: 16px;
                .locked{
                  color: $color-6;
                  margin-right: 3px;
                  margin-left: 4px;
                }
            }
            .circle{
                width: 32px;
                height: 32px;
                background: #F8F8F8;
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                padding-top: 3px;
                margin-left: 30px;
                .text{
                    font-weight: 700;
                    font-size: 16px;
                    color: #C4C4C4;
                }
                .locked{
                  color: $color-6;
                }
            }
            .name {
                margin-left: 15px;
            }
          }
        }
      }
}

@media (max-width: 450px) { 
  .votingDetailsCard{
      height: auto !important;
      border-radius: 34px;
       margin: 35px auto 0;
  }
}