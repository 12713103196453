@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.buttons-box {
  background-color: $color-7;
  padding: 30px 25px;
  border-radius: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .width100{
    width: 100% !important
  }

  .bgGray{
    background-color: #F2F0F2 !important;
  }

  .endGameTitle{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #463649;
  }
  & > .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: $recoleta;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    margin-bottom: 25px;
    left: -10px;
    
    img {
      margin-right: 10px;
    }
  }

  .button-default {
    width: 100%;
    margin-bottom: 12px;

    &.secondary {
      width: calc((100% / 2) - 5px);
    }
  }

  .body-modal {
    p {
      margin: 0;
    }

    .title {
      font-family: $recoleta;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 54.5px;
      max-width: 453px;
    }

    .description {
      max-width: 374px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 19px;
      text-align: center;
    }

    button {
      display: flex;
      max-width: 307px;
      margin: 0 auto 13px;

      &.facebook {
        background: #1877F2;
        color: #fff;
        border-color: #1877F2;
      }

      &.whatsapp {
        background: #25D366;
        color: #fff;
        border-color: #25D366;
      }

      img {
        margin-right: 5px;
      }
    }
  }
}


@media (max-width: 450px) { 
  .buttons-box{
    .endGameTitle{
      font-size: 28px;
    }

    & > .title{
      letter-spacing: 1.1px !important;
      font-size: 28px;
    }
  }
}